import { LabelData, useLabels } from "@/core/context/LabelsContext"
import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"

export function useProductLabel(
  productType?: ProductType
): Pick<LabelData, "singular" | "plural"> {
  const labels = useLabels()
  switch (productType) {
    case "membership_plan":
      return { singular: "Membership Plan", plural: "Membership Plans" }
    case "community_event":
      return { singular: "Community Event", plural: "Community Events" }
    case "pathway":
      return labels.pathway
    // Handle "%future added value" instead of default case so product types added in the
    // future will cause a type error if not handled here
    case "course":
    case "%future added value":
    case undefined:
      return labels.experience
  }
}

export function useAdminProductLabel(
  productType?: ProductType
): Pick<LabelData, "singular" | "plural"> {
  const labels = useLabels()
  switch (productType) {
    case "membership_plan":
      return { singular: "Membership Plan", plural: "Membership Plans" }
    case "community_event":
      return { singular: "Community Event", plural: "Community Events" }
    case "pathway":
      return { singular: "Pathway", plural: "Pathways" }
    case "course":
    case "%future added value":
    case undefined:
      return labels.admin_experience
  }
}

export function useProductMemberLabel(
  productType?: ProductType
): Pick<LabelData, "singular" | "plural"> {
  const labels = useLabels()
  switch (productType) {
    case "pathway":
    case "membership_plan":
    case "community_event":
      return labels.organization_member
    case "course":
    case "%future added value":
    case undefined:
      return labels.product_member
  }
}
