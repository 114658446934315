import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useProductsAdminLearnMode } from "@/core/context/ProductsAdminLearnModeContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isAdminConsolePath } from "@/core/route/util/routeUtils"
import { useLocation, useRouteMatch } from "react-router-dom"

function useIsInLearnMode() {
  const location = useLocation()
  const isOnAdminConsolePath = isAdminConsolePath(location.pathname)
  const activeProduct = useActiveProduct()
  const { isViewerInLearnModeForProduct, isViewerInLearnModeForChatChannel } =
    useProductsAdminLearnMode()
  const chatChannelRoute = useRouteMatch(ROUTE_NAMES.CHAT.CHANNEL.DETAIL)

  if (isOnAdminConsolePath) return false

  if (activeProduct) return isViewerInLearnModeForProduct(activeProduct.id)

  if (chatChannelRoute) {
    const { channelId } = chatChannelRoute.params as { channelId?: string }
    if (!channelId) return false
    return isViewerInLearnModeForChatChannel(channelId)
  }

  return false
}

export default useIsInLearnMode
