import useSearchClient from "@/apps/util/hooks/useSearchClient"
import { useLogout } from "@/authentication/logout/util/useLogout"
import { useRegistrationSendVerificationEmail } from "@/authentication/signup/util/useRegistrationSendVerificationEmail"
import {
  isSupportedCheckoutProduct,
  useStartEntityCheckout,
} from "@/checkout/utils/CheckoutUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { AuthUserData, useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CheckoutFormOrganizationFormSection from "@/product/checkout/form/organization-form/CheckoutFormOrganizationFormSection"
import CheckoutFormProfileNewUserSection from "@/product/checkout/form/registration/CheckoutFormProfileNewUserSection"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import useCompleteCheckoutMutation from "@/product/checkout/store/useCompleteCheckoutMutation"
import useUpdateCheckoutMutation from "@/product/checkout/store/useUpdateCheckoutMutation"
import useUpdateCompleteProfileItems from "@/product/course/curriculum/util/useUpdateCompleteProfileItems"
import { ProductRegistrationProfileStepMutation } from "@/product/register/steps/__generated__/ProductRegistrationProfileStepMutation.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoInput,
  DiscoText,
  DiscoTextButton,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"
import { ProductRegistrationProfileStepGuestMutation } from "./__generated__/ProductRegistrationProfileStepGuestMutation.graphql"

interface Props extends TestIDProps {
  store: CheckoutStore
}

function ProductRegistrationProfileStep({ store }: Props) {
  const classes = useStyles()
  const updateCheckout = useUpdateCheckoutMutation(store)
  const completeCheckout = useCompleteCheckoutMutation(store)
  const logout = useLogout()
  const theme = useTheme()
  const updateCompleteProfileCompletionMutation = useUpdateCompleteProfileItems()
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()
  const { authUser } = useAuthUser()
  const search = useSearchClient()
  const registrationSendVerificationEmail = useRegistrationSendVerificationEmail()
  const { startEntityCheckout } = useStartEntityCheckout(store.product)

  // Mark the profile as incomplete if the user is missing a first or last name
  const incompleteProfile = authUser ? !authUser?.firstName || !authUser?.lastName : false
  const completeGuestCheckoutMutation =
    Relay.useAsyncMutation<ProductRegistrationProfileStepGuestMutation>(
      graphql`
        mutation ProductRegistrationProfileStepGuestMutation($id: ID!) {
          completeGuestCheckout(id: $id) {
            node {
              id
            }
            errors {
              field
              message
            }
          }
        }
      `
    )

  const form = useFormStore<ProductRegistrationProfileStepMutation>(
    graphql`
      mutation ProductRegistrationProfileStepMutation($input: UpdateUserProfileInput!) {
        response: updateProfile(input: $input) {
          node {
            ... on User {
              firstName
              lastName
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: authUser ? authUser?.id : "",
      firstName: authUser?.firstName || "",
      lastName: authUser?.lastName || "",
    }
  )

  const [isCreatingUser, setIsCreatingUser] = useState(false)

  return (
    <>
      <DiscoText variant={"body-md-600"} marginBottom={1.5}>
        {"Registration"}
      </DiscoText>

      {/* Auth user profile w/ option to log out */}
      {!store.isForNewUser && authUser && (
        <div data-testid={"CheckoutForm.auth-user-section"}>
          <DiscoText variant={"body-md"} color={"text.secondary"}>
            {"You're logged in and registering as"}
          </DiscoText>

          <div className={classes.avatar}>
            <ProfileAvatarWithDetails
              testid={"CheckoutForm"}
              userKey={authUser}
              details={authUser.email}
              titleVariant={"body-md-500"}
              subtitleVariant={"body-sm"}
              truncateName={2}
            />
          </div>

          <DiscoText variant={"body-md"} color={"text.secondary"} marginBottom={2}>
            {"or "}
            <span>
              <DiscoTextButton
                onClick={logout}
                textVariant={"body-md"}
                color={theme.palette.primary.main}
                className={classes.signOut}
                testid={`CheckoutForm.signout`}
              >
                {"Sign Out"}
              </DiscoTextButton>
            </span>
            {" to register under a different account."}
          </DiscoText>
        </div>
      )}

      {/* Incomplete profile */}
      {incompleteProfile ? (
        <div>
          <DiscoInput
            fullWidth
            placeholder={"First Name"}
            name={"first-name"}
            value={form.state.firstName || ""}
            onChange={(e) => (form.state.firstName = e.target.value)}
            data-testid={"ProductRegistrationProfileStep.incomplete.first-name"}
            className={classes.profileFields}
          />

          <DiscoInput
            fullWidth
            placeholder={"Last Name"}
            name={"last-name"}
            value={form.state.lastName || ""}
            onChange={(e) => (form.state.lastName = e.target.value)}
            data-testid={"ProductRegistrationProfileStep.incomplete.last-name"}
            className={classes.profileFields}
          />
        </div>
      ) : (
        <>
          {/* New user input fields */}
          {store.isForNewUser && <CheckoutFormProfileNewUserSection store={store} />}

          {/* Add the organization form once they are on the final registration page */}
          {!store.checkout?.hasApplication && authUser && (
            <CheckoutFormOrganizationFormSection store={store} />
          )}
        </>
      )}

      {/* Next step */}
      <div className={classes.joinButton}>
        {store.hasSelectedEmailSignup && (
          <DiscoTextButton
            onClick={() => {
              store.hasSelectedEmailSignup = false
            }}
            className={classes.backToPreviousButton}
          >
            {"Back"}
          </DiscoTextButton>
        )}
        {/* If the user is logged in, then we should show them the registration options that are just for them */}
        {authUser ? (
          <>
            {/* If the user's profile is incomplete, submit the form to complete it */}
            {incompleteProfile ? (
              <DiscoButton
                data-testid={"ProductRegistrationProfileStep.update-profile"}
                disabled={
                  !form.state.firstName || !form.state.lastName || form.isSubmitting
                }
                shouldDisplaySpinner={store.isSubmitting || isCreatingUser}
                onClick={handleUpdateProfile}
                width={"100%"}
              >
                {"Complete Profile"}
              </DiscoButton>
            ) : store.checkout?.occurrence?.allowGuestAccess ? (
              <div className={classes.container}>
                <DiscoButton
                  type={"submit"}
                  disabled={!canSubmitForm()}
                  shouldDisplaySpinner={store.isSubmitting}
                  data-testid={"ProductRegistrationProfileStep.submit-guest-button"}
                  onClick={handleSubmitGuest}
                  width={"100%"}
                >
                  {"Attend as Guest"}
                </DiscoButton>

                {store.checkout?.isOrganizationPublic && (
                  <DiscoButton
                    type={"submit"}
                    disabled={!canSubmitForm()}
                    shouldDisplaySpinner={store.isSubmitting || isCreatingUser}
                    data-testid={"ProductRegistrationProfileStep.submit-button"}
                    onClick={handleSubmit}
                    width={"100%"}
                  >
                    {authUser
                      ? store.requiresPaymentStep
                        ? "Next Step"
                        : "Attend & Join Community"
                      : "Sign Up"}
                  </DiscoButton>
                )}
              </div>
            ) : (
              <>
                {/* If there is an application, move to the application step, otherwise complete the registration */}
                {store.checkout?.hasApplication ? (
                  <DiscoButton
                    data-testid={"ProductRegistrationProfileStep.start-application"}
                    disabled={!canStartApplicationStep()}
                    shouldDisplaySpinner={store.isSubmitting || isCreatingUser}
                    onClick={handleStartApplication}
                    width={"100%"}
                  >
                    {"Start Application"}
                  </DiscoButton>
                ) : (
                  <DiscoButton
                    type={"submit"}
                    disabled={!canSubmitForm()}
                    shouldDisplaySpinner={store.isSubmitting || isCreatingUser}
                    data-testid={"ProductRegistrationProfileStep.submit-button"}
                    onClick={handleSubmit}
                    width={"100%"}
                  >
                    {store.requiresPaymentStep
                      ? "Next Step"
                      : store.isGifted
                      ? "Claim this Gift"
                      : "Complete Registration"}
                  </DiscoButton>
                )}
              </>
            )}
          </>
        ) : (
          <DiscoButton
            type={"submit"}
            disabled={!canSubmitProfileForm()}
            shouldDisplaySpinner={store.isSubmitting || isCreatingUser}
            data-testid={"ProductRegistrationProfileStep.submit-button"}
            onClick={handleUserSignUp}
            width={"100%"}
          >
            {"Sign Up"}
          </DiscoButton>
        )}
      </div>
    </>
  )

  function handleStartApplication() {
    store.currentStep = "apply"
  }

  // If a user is signing up (email verification) then we just want to go through that flow
  async function handleUserSignUp(e: React.FormEvent) {
    e.preventDefault()
    if (!canSubmitProfileForm()) return

    const isValid = await updateCheckout()
    if (!isValid) return

    try {
      // Push the user to email verification if they are signing up
      // and have it submit for the verification code
      setIsCreatingUser(true)

      if (
        await registrationSendVerificationEmail({
          checkoutId: store.checkout?.id || "",
        })
      ) {
        store.currentStep = "verify_email"
      }
    } catch (err) {
      displayErrorToast(err)
    } finally {
      setIsCreatingUser(false)
    }
  }

  // If a logged in user is trying to complete the checkout, then they will call this function
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    if (!canSubmitForm()) return
    const isValid = await updateCheckout()
    if (!isValid) return
    if (!authUser) return

    try {
      updateUserInStore(authUser)

      // If using the latest checkout, go to complete it and start the new flow
      if (
        activeOrganization?.checkoutVersion === "stripe_acacia" &&
        isSupportedCheckoutProduct({
          checkoutVersion: activeOrganization?.checkoutVersion,
          productType: store.product?.type,
        })
      ) {
        return startEntityCheckout()
      }

      // Otherwise complete the checkout using the old flow
      else if (store.requiresPaymentStep) {
        // Paid Product
        store.currentStep = "payment"
      } else {
        // Free Product
        await completeCheckout()
      }

      // Refresh the user's search config
      search?.refreshSearch()
    } catch (err) {
      displayErrorToast(err)
    }
  }

  async function handleSubmitGuest() {
    if (!store.checkout?.occurrence) return
    store.isSubmitting = true
    const res = await completeGuestCheckoutMutation({ id: store.checkout.id })
    if (res.completeGuestCheckout.errors?.length) {
      displayErrorToast(res.completeGuestCheckout.errors[0])
      return
    }
    store.isSubmitting = false

    // Push them to the registration screen for the occurrence they just joined
    window.location.href = generatePath(ROUTE_NAMES.COMMUNITY.GUEST.DETAIL, {
      entityId: store.checkout.occurrence.id,
    })
  }

  function updateUserInStore(user: AuthUserData<true>) {
    runInAction(() => {
      store.profile.userId = Relay.rawId(user.id)
      store.profile.email = user.email
      store.profile.firstName = user.firstName
      store.profile.lastName = user.lastName
      store.profile.timezone = user.timezone
    })
  }

  function canSubmitProfileForm(): boolean {
    if (isCreatingUser) return false
    if (store.isSubmitting) return false
    if (!store.hasAcceptedTerms) return false

    if (
      !store.profile.firstName ||
      !store.profile.lastName ||
      !store.profile.email ||
      !store.profile.confirmEmail ||
      store.isEmailConfirmationWrong
    ) {
      return false
    }
    return true
  }

  function canSubmitForm(): boolean {
    if (isCreatingUser) return false
    if (store.isSubmitting) return false
    if (!activeOrganization?.viewerMembership && !store.hasAcceptedTerms && !authUser) {
      return false
    }
    if (!store.canCompleteRegistrationStep) return false
    return true
  }

  function canStartApplicationStep(): boolean {
    return Boolean(
      store.profile.userId ||
        (store.profile.firstName &&
          store.profile.lastName &&
          store.profile.email &&
          store.profile.confirmEmail &&
          !store.isEmailConfirmationWrong)
    )
  }

  async function handleUpdateProfile() {
    try {
      if (!form.state.firstName || !form.state.lastName) {
        form.addError({
          field: form.state.firstName ? "lastName" : "firstName",
          message: "First and last name are required",
        })
        return
      }
      const { didSave, response } = await form.submit(form.state)
      if (!didSave || !response?.node) return

      // Track completion of the task
      await commitCompletionMutation()
    } catch (error) {
      displayErrorToast(error)
    }
  }

  async function commitCompletionMutation() {
    // Update all the complete profile items in any product the member is in,
    await updateCompleteProfileCompletionMutation({
      input: {},
      productId: activeProduct?.id || "",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  profileFields: {
    marginBottom: theme.spacing(2),
  },
  joinButton: {
    width: "100%",
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(3),
    justifyContent: "flex-end",
  },
  backToPreviousButton: {
    color: theme.palette.groovy.grey[300],
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  avatar: {
    border: theme.palette.constants.borderSmall,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2.5),
    margin: theme.spacing(2, 0),
  },
  signOut: {
    paddingBottom: theme.spacing(0.25),
  },
}))

export const ProductRegistrationProfileStepSkeleton: React.FC = () => {
  return (
    <>
      <DiscoTextSkeleton variant={"body-md-600"} marginBottom={2} width={"45%"} />
      <DiscoTextSkeleton variant={"body-sm"} marginBottom={2} width={"80%"} />
      <Skeleton width={"100%"} height={75} />
      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ProductRegistrationProfileStep),
  skeleton: ProductRegistrationProfileStepSkeleton,
})
