import { useLabels } from "@/core/context/LabelsContext"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoSwitch, DiscoText } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"
interface ExperienceSettingsLearnModeFieldsProps extends TestIDProps {
  form: {
    initialState: Partial<Pick<ExperienceSettingsFormState, "adminCanLearnMode">>
    state: Partial<Pick<ExperienceSettingsFormState, "adminCanLearnMode">>
    errorsByField: Record<string, string[] | undefined>
  }
}

function ExperienceSettingsLearnModeFields({
  form,
  testid = "ExperienceSettingsLearnModeFields",
}: ExperienceSettingsLearnModeFieldsProps) {
  const [showWarningModal, setShowWarningModal] = useState(false)

  const labels = useLabels()
  const classes = useStyles()

  return (
    <div>
      <DiscoFormControl
        error={Boolean(form.errorsByField.adminCanLearnMode)}
        errorMessages={form.errorsByField.adminCanLearnMode || []}
      >
        <div className={classes.switchContainer}>
          <DiscoSwitch
            name={"toggle-admin-can-learn-mode"}
            checked={Boolean(form.state.adminCanLearnMode)}
            testid={`${testid}.switch.fixed`}
            label={
              <ExperienceSettingsLabel
                testid={`${testid}.label`}
                title={`Allow Learn Mode for this ${labels.admin_experience.singular}`}
                tooltipContent={`When enabled, ${labels.organization_admin.plural} and ${labels.product_instructor.plural} will be able to complete the curriculum and their progress will be recorded in all ${labels.admin_experience.singular} reports.`}
                learnMoreUrl={
                  "https://support.disco.co/hc/en-us/articles/32460987506196-Learning-Mode-for-Admins"
                }
              />
            }
            sublabel={
              <DiscoText variant={"body-sm"}>
                {`${labels.organization_admin.plural} and ${labels.product_admin.plural} can switch to Learn Mode to complete the ${labels.admin_experience.singular} as a ${labels.product_member.singular}.`}
              </DiscoText>
            }
            onChange={(checked) => {
              if (form.initialState.adminCanLearnMode && !checked) {
                // If turning off and this was initially set to true, open the warning modal
                setShowWarningModal(true)
              } else {
                form.state.adminCanLearnMode = checked
              }
            }}
          />
        </div>
      </DiscoFormControl>

      <DiscoWarningModal
        testid={"ExperienceSettingsLearnModeFields.disabling-warning-modal"}
        modalContentLabel={"Disable Learn Mode"}
        isOpen={showWarningModal}
        title={`Disable Learn Mode for this ${labels.admin_experience.singular}?`}
        description={`Turning this setting off will prevent ${labels.organization_admin.plural} and ${labels.product_admin.plural} from switching to Learn Mode. Their completion records will be removed from all reports.`}
        confirmationButtonProps={{
          onClick: () => {
            form.state.adminCanLearnMode = false
            setShowWarningModal(false)
          },
          children: "Disable Learn Mode",
        }}
        onClose={() => setShowWarningModal(false)}
      />
    </div>
  )
}

const useStyles = makeUseStyles({
  switchContainer: {
    display: "inline-flex",
    flexDirection: "column",
  },
})

export default observer(ExperienceSettingsLearnModeFields)
