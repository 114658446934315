import useSearchClient from "@/apps/util/hooks/useSearchClient"
import {
  isSupportedCheckoutProduct,
  useStartEntityCheckout,
} from "@/checkout/utils/CheckoutUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import PaymentFormDiscountSection from "@/product/checkout/form/discount/PaymentFormDiscountSection"
import CheckoutFormPaymentMethodForm from "@/product/checkout/form/payment/CheckoutFormPaymentMethodForm"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import useCompleteCheckoutMutation from "@/product/checkout/store/useCompleteCheckoutMutation"
import useUpdateCheckoutMutation from "@/product/checkout/store/useUpdateCheckoutMutation"
import { ProductRegistrationPaymentStep_Checkout$key } from "@/product/register/steps/__generated__/ProductRegistrationPaymentStep_Checkout.graphql"
import Relay from "@/relay/relayUtils"
import useHandleFuturePayment from "@/stripe/util/hook/useHandleFuturePayment"
import { displayGraphQLErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoCheckbox,
  DiscoCheckboxSkeleton,
  DiscoDivider,
  DiscoFormControlSkeleton,
  DiscoInputSkeleton,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { TestIDProps } from "@utils/typeUtils"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  store: CheckoutStore
}

function ProductRegistrationPaymentStep({ store }: Props) {
  const activeOrganization = useActiveOrganization()
  const stripe = useStripe()
  const elements = useElements()
  const search = useSearchClient()
  const handleFuturePayment = useHandleFuturePayment()
  const updateCheckout = useUpdateCheckoutMutation(store)
  const completeCheckout = useCompleteCheckoutMutation(store)
  const { startEntityCheckout } = useStartEntityCheckout(store.product)

  const classes = useStyles()

  const checkout = useFragment<ProductRegistrationPaymentStep_Checkout$key>(
    graphql`
      fragment ProductRegistrationPaymentStep_Checkout on Checkout {
        id
        product {
          type
          # Pricing for a membership plan
          pricing {
            ...PaymentFormDiscountSection_Pricing
          }
        }
        quote {
          basePrice
          finalPrice
          discount {
            ...PaymentFormDiscountSection_Discount
          }
        }
      }
    `,
    toJS(store.checkout || null)
  )

  if (!checkout || !store.product) return null

  // If latest, start the new checkout process
  if (
    activeOrganization?.checkoutVersion === "stripe_acacia" &&
    isSupportedCheckoutProduct({
      checkoutVersion: activeOrganization?.checkoutVersion,
      productType: store.product.type,
    })
  ) {
    startEntityCheckout()
    return null
  }

  return (
    <>
      <DiscoText
        variant={"body-md-600"}
        marginBottom={3}
        data-testid={"ProductRegistrationPaymentStep.title"}
      >
        {"Payment Details"}
      </DiscoText>

      {store.hasPayment && (
        <>
          <CheckoutFormPaymentMethodForm store={store} />
          <DiscoDivider marginTop={3} marginBottom={3} />
          {/* Checkbox to acknowledge future payment on application approval */}
          {store.checkout?.hasApplication && (
            <DiscoCheckbox
              name={"accept-future-payment"}
              checked={store.hasAcceptedFutureCharge}
              onChange={(v) => (store.hasAcceptedFutureCharge = v)}
              className={classes.futureChargeCheckbox}
              label={`If accepted, I acknowledge that I will be charged the amount below.`}
            />
          )}
        </>
      )}

      <PaymentFormDiscountSection
        testid={"ProductRegistrationPaymentStep"}
        basePrice={checkout.quote.basePrice}
        finalPrice={checkout.quote.finalPrice}
        discountKey={checkout.quote.discount}
        pricingKey={checkout.product.pricing}
        // Don't show the discount input if the product is a membership plan
        showInput={checkout.product.type !== "membership_plan"}
        store={store}
      />

      <DiscoDivider marginTop={3} marginBottom={3} />

      <DiscoButton
        type={"submit"}
        disabled={!store.canCompletePaymentStep || store.isSubmitting}
        shouldDisplaySpinner={store.isSubmitting}
        width={"100%"}
        data-testid={"ProductRegistrationPaymentStep.complete-button"}
        onClick={handleSubmit}
      >
        {getButtonTitle()}
      </DiscoButton>
    </>
  )

  /** Generate text for the submit button */
  function getButtonTitle(): string {
    if (store.hasPayment) {
      // Paid, with application
      if (store.checkout?.hasApplication) {
        return "Pre-Authorize and Apply"
      }
      // Paid, no application
      return "Complete Purchase"
    }
    // Free, with application
    if (store.checkout?.hasApplication) {
      return "Complete Application"
    }
    // Free, no application
    return "Complete Registration"
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    if (store.isSubmitting) return
    store.isSubmitting = true

    try {
      if (checkout?.quote.finalPrice) {
        await handlePayment()
      }
      await completeCheckout()

      // Refresh the user's search config
      search?.refreshSearch()
    } catch (err) {
      displayGraphQLErrorToast(err)
    }
    store.isSubmitting = false
  }

  async function handlePayment() {
    const { cardHolderName, postalCode } = store.payment.newPaymentMethod
    if (!cardHolderName) throw new Error("Enter a card holder name")
    if (!postalCode) throw new Error("Enter a zip or postal code")
    if (!store.profile.email) throw new Error("No email found")
    if (!store.checkout) throw new Error("No checkout found")
    if (!store.product) throw new Error("No product found")
    if (!stripe || !elements) throw new Error("Missing stripe or elements")

    // Create Stripe payment method
    const stripeResponse = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)!,
      billing_details: {
        name: cardHolderName,
        address: {
          postal_code: postalCode,
        },
      },
    })
    if (stripeResponse.error)
      throw new Error("You must provide a valid credit card for payment.")

    // Save payment method to checkout
    await updateCheckout({
      paymentMethodId: stripeResponse.paymentMethod.id,
    })

    try {
      store.isSubmitting = true
      // Create setupIntent to process payment later
      await handleFuturePayment(
        { paymentMethodId: stripeResponse.paymentMethod.id },
        stripe
      )
    } finally {
      store.isSubmitting = false
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  futureChargeCheckbox: {
    marginBottom: theme.spacing(4),
  },
}))

export const ProductRegistrationPaymentStepSkeleton: React.FC<{
  store: CheckoutStore
}> = (props) => {
  const { store } = props
  const theme = useTheme()
  return (
    <>
      <DiscoTextSkeleton variant={"body-md-600"} marginBottom={3} width={"45%"} />

      {store.hasPayment && (
        <>
          <DiscoFormControlSkeleton
            variant={"one-column"}
            input={<DiscoInputSkeleton />}
          />
          <DiscoFormControlSkeleton
            variant={"one-column"}
            input={<DiscoInputSkeleton />}
          />
          <div style={{ display: "flex", gap: theme.spacing(1) }}>
            <div style={{ width: "50%" }}>
              <DiscoFormControlSkeleton
                variant={"one-column"}
                input={<DiscoInputSkeleton />}
              />
            </div>
            <div style={{ width: "50%" }}>
              <DiscoFormControlSkeleton
                variant={"one-column"}
                input={<DiscoInputSkeleton />}
              />
            </div>
          </div>
          <DiscoFormControlSkeleton
            variant={"one-column"}
            input={<DiscoInputSkeleton />}
          />

          <DiscoDivider marginTop={3} marginBottom={3} />

          {store.checkout?.hasApplication && <DiscoCheckboxSkeleton />}
        </>
      )}

      <DiscoTextSkeleton width={"60%"} height={"50px"} marginTop={2} />

      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: theme.spacing(2),
          gap: theme.spacing(1),
        }}
      >
        <div style={{ width: "75%" }}>
          <DiscoInputSkeleton />
        </div>
        <DiscoButtonSkeleton width={"25%"} />
      </div>

      <DiscoDivider marginBottom={3} marginTop={3} />

      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ProductRegistrationPaymentStep),
  skeleton: ProductRegistrationPaymentStepSkeleton,
})
