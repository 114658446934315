import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CheckoutFormRegistrationErrorStep from "@/product/checkout/form/registration/CheckoutFormRegistrationErrorStep"
import { GiftProductFormFragment$key } from "@/product/gift/__generated__/GiftProductFormFragment.graphql"
import GiftProductFormPaymentStep from "@/product/gift/payment/GiftProductFormPaymentStep"
import GiftProductFormRegistrationStep from "@/product/gift/registration/GiftProductFormRegistrationStep"
import {
  GiftProductInput,
  ProductRegistration_GiftMutation,
} from "@/product/register/__generated__/ProductRegistration_GiftMutation.graphql"
import StripeUtils from "@/stripe/util/StripeUtils"
import { Elements } from "@stripe/react-stripe-js"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type GiftProductFormStoreState = GiftProductInput & {
  step: "registration" | "payment" | "success"
  gifter: {
    isNewUser: boolean
    firstName: string
    lastName: string
    email: string
    confirmEmail: string
  }
  confirmRecipientEmail: string
  payment: {
    cardHolderName: string
    postalCode: string
  }
  hasAcceptedTerms: boolean
}

export type GiftProductFormStore = FormStore<
  GiftProductFormStoreState,
  ProductRegistration_GiftMutation
>

interface Props {
  form: GiftProductFormStore
  productKey: GiftProductFormFragment$key
  className?: string
}

const GiftProductForm = observer<Props>((props) => {
  const { form, productKey, className } = props

  const product = useFragment<GiftProductFormFragment$key>(
    graphql`
      fragment GiftProductFormFragment on Product {
        ...CheckoutFormRegistrationErrorStep_Product
        ...GiftProductFormPaymentStepFragment
        canBeGiftedError {
          message
        }
      }
    `,
    productKey
  )
  const experienceLabel = useLabel("experience")
  const classes = useStyles()
  const stripePromise = StripeUtils.useLoadStripe()

  return (
    <Elements stripe={stripePromise}>
      <div
        data-testid={"ProductRegistration.container"}
        className={classNames(classes.container, className)}
      >
        {product.canBeGiftedError ? (
          <CheckoutFormRegistrationErrorStep
            errorMessage={product.canBeGiftedError.message}
            title={`Gift This ${experienceLabel.singular}`}
            authUserLabel={"Send gift as:"}
            productKey={product}
          />
        ) : form.state.step === "registration" ? (
          <GiftProductFormRegistrationStep form={form} />
        ) : form.state.step === "payment" ? (
          <GiftProductFormPaymentStep form={form} productKey={product} />
        ) : null}
      </div>
    </Elements>
  )
})

const useStyles = makeUseStyles((theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 8, 6, 6),
      height: "100%",
    },
  },
}))

export default GiftProductForm
