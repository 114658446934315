import { AuthUserData, useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import {
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoText,
  DiscoTextButton,
  DiscoTextSkeleton,
} from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"
import CheckoutStore from "../checkout/store/CheckoutStore"
import { ProductRegistrationVerifyEmailStepMutation } from "./__generated__/ProductRegistrationVerifyEmailStepMutation.graphql"

interface ProductRegistrationVerifyEmailStepProps extends TestIDProps {
  store: CheckoutStore
}

function ProductRegistrationVerifyEmailStep({
  testid,
  store,
}: ProductRegistrationVerifyEmailStepProps) {
  const classes = useStyles()
  const { refreshAuthUser } = useAuthUser()

  const form = useFormStore<ProductRegistrationVerifyEmailStepMutation>(
    graphql`
      mutation ProductRegistrationVerifyEmailStepMutation($input: RegisterUserInput!) {
        response: registerUser(input: $input) {
          node {
            id
            firstName
            lastName
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      checkoutId: store.checkout?.id || "",
      verificationCode: "",
    },
    { requireChangeToSubmit: false }
  )

  return (
    <>
      <Form
        testid={testid}
        classes={{ buttonsRoot: classes.buttonsContainer }}
        buttons={
          <>
            <DiscoTextButton
              onClick={() => {
                store.isSubmitting = false
                store.currentStep = "registration"
              }}
              className={classes.backToPreviousButton}
            >
              {"Back"}
            </DiscoTextButton>
            <Form.SubmitButton
              name={"verify-email"}
              testid={"VerifyEmailStep.button.verify-email"}
              id={`${testid}VerifyEmailStepForm`}
              form={form}
              onClick={handleSubmit}
              className={classes.verifyEmailButton}
              disabled={form.isSubmitting || !form.state.verificationCode}
            >
              {"Verify Email"}
            </Form.SubmitButton>
          </>
        }
      >
        <DiscoText variant={"body-md-600"} marginBottom={2}>
          {"Registration"}
        </DiscoText>
        <DiscoText variant={"body-sm"}>
          {`A verification code has been sent to `}
          <DiscoText variant={"body-sm-600"} component={"span"}>
            {store.checkout?.profile.email}
          </DiscoText>
          {`. Enter the code below to complete your registration.`}
        </DiscoText>

        <DiscoDivider marginTop={3} marginBottom={3} />

        <DiscoFormControl
          errorMessages={form.errorsByField.verificationCode}
          marginBottom={0.5}
        >
          <DiscoInput
            fullWidth
            placeholder={"Verification Code"}
            name={"verification-code"}
            value={form.state.verificationCode || ""}
            onChange={(e) => (form.state.verificationCode = e.target.value)}
            data-testid={`${testid}VerifyEmailStep.input.verification-code`}
          />
        </DiscoFormControl>
      </Form>

      <DiscoDivider marginTop={3} marginBottom={2} />
      <DiscoText variant={"body-sm"} align={"center"}>
        {
          "If you didn't receive the code, please go back to verify your information and try again."
        }
      </DiscoText>
    </>
  )
  function updateUserInStore(user: AuthUserData<true>) {
    runInAction(() => {
      store.profile.userId = Relay.rawId(user.id)
      store.profile.email = user.email
      store.profile.firstName = user.firstName
      store.profile.lastName = user.lastName
      store.profile.timezone = user.timezone
    })
  }

  async function handleSubmit() {
    if (!form.state.verificationCode) {
      form.addError({
        field: "verificationCode",
        message: "Verification code is required",
      })
      return
    }
    store.isSubmitting = true
    const { didSave, response } = await form.submit(form.state)
    if (!didSave || !response?.node) return

    const newAuthUser = await refreshAuthUser()
    updateUserInStore(newAuthUser)
    // Push back to registration so that they can continue as a "logged in" user
    // we also have to unset a lot of values that lead to odd UI
    store.hasSelectedEmailSignup = false
    store.isForNewUser = false
    store.currentStep = "registration"
    store.isSubmitting = false
  }
}

const useStyles = makeUseStyles((theme) => ({
  verifyEmailButton: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(3),
    justifyContent: "center",
  },
  backToPreviousButton: {
    color: theme.palette.groovy.grey[300],
  },
  buttonsContainer: {
    marginTop: theme.spacing(1.5),
  },
}))

export const ProductRegistrationVerifyEmailStepSkeleton: React.FC = () => {
  return (
    <>
      <DiscoTextSkeleton variant={"body-md-600"} marginBottom={2} width={"45%"} />
      <DiscoTextSkeleton variant={"body-sm"} marginBottom={2} width={"80%"} />
      <Skeleton width={"100%"} height={75} />
      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<ProductRegistrationVerifyEmailStepProps>({
  component: observer(ProductRegistrationVerifyEmailStep),
  skeleton: ProductRegistrationVerifyEmailStepSkeleton,
})
