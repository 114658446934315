import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import MemberProgressBarLegendTooltip from "@/product/reports/MemberProgressBarLegendTooltip"
import { MemberCurriculumProgressBarFragment$key } from "@/product/reports/page/curriculum/__generated__/MemberCurriculumProgressBarFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText, DiscoTextSkeleton, DiscoTooltip } from "@disco-ui"
import DiscoProgressBar from "@disco-ui/progress/DiscoProgressBar"
import { Skeleton } from "@material-ui/lab"
import { truncateDecimalPart } from "@utils/number/numberUtils"
import classNames from "classnames"
import React from "react"
import { graphql, useFragment } from "react-relay"

export interface MemberCurriculumProgressBarProps {
  memberKey: MemberCurriculumProgressBarFragment$key
  hideLabels?: boolean
  labelVariant?: "percentage" | "modules"
  width?: string
  className?: string
}

const MemberCurriculumProgressBar: React.FC<MemberCurriculumProgressBarProps> = ({
  memberKey,
  hideLabels = true,
  labelVariant = "percentage",
  width = "72px",
  className,
}) => {
  const classes = useStyles({ hideLabels, width })

  const membership = useFragment<MemberCurriculumProgressBarFragment$key>(
    graphql`
      fragment MemberCurriculumProgressBarFragment on ProductMembership {
        id
        curriculumModuleCompletions {
          totalCount
        }
        product {
          curriculum {
            modules {
              totalCount
              edges {
                node {
                  id
                  releasedAt
                }
              }
            }
          }
        }
      }
    `,
    memberKey
  )

  if (!membership) return null

  const total = getTotalModules()
  const completed = getCompletedModules()
  const totalReleased = getTotalReleased()

  const progress = total ? (completed / total) * 100 : 0
  const bufferProgress = total ? (totalReleased / total) * 100 : 0

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.labels}>
        {!hideLabels && (
          <DiscoText
            variant={"body-sm-500"}
            display={"inline"}
            align={"left"}
            color={"groovy.green.400"}
            className={classes.progressLabel}
            data-testid={"MemberCurriculumProgressBar.percentage"}
          >
            {`${truncateDecimalPart(0)(progress)}%`}
          </DiscoText>
        )}

        <DiscoText
          data-testid={"MemberCurriculumProgressBar.modules-completed"}
          variant={"body-sm"}
          color={"text.secondary"}
          display={"inline"}
          align={"right"}
          className={classes.progressText}
        >
          {renderLabel()}
        </DiscoText>
      </div>

      <DiscoTooltip
        content={
          <MemberProgressBarLegendTooltip
            progress={progress}
            bufferProgress={bufferProgress}
            variant={"curriculum"}
            total={total}
          />
        }
      >
        <div>
          <DiscoProgressBar
            percentage={progress}
            bufferPercentage={bufferProgress}
            width={width}
          />
        </div>
      </DiscoTooltip>
    </div>
  )

  function getTotalModules() {
    return membership.product.curriculum!.modules.totalCount
  }

  function getCompletedModules() {
    return membership.curriculumModuleCompletions?.totalCount || 0
  }

  function getTotalReleased() {
    return Relay.connectionToArray(membership.product.curriculum!.modules).filter(
      (module) => ContentModuleUtils.isReleased(module)
    ).length
  }

  function renderLabel() {
    switch (labelVariant) {
      case "percentage":
        return `${truncateDecimalPart(0)(progress)}%${hideLabels ? "" : " Complete"}`
      case "modules":
        return `${completed}/${total} Modules Completed`
    }
  }
}

type StyleProps = {
  hideLabels?: boolean
  width: string
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ width }: StyleProps) => ({
    width,
  }),
  labels: ({ hideLabels }: StyleProps) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    ...styleIf(!hideLabels, {
      gap: theme.spacing(4),
    }),
  }),
  progressLabel: ({ hideLabels }: StyleProps) => ({
    ...styleIf(!hideLabels, {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[500],
    }),
  }),
  progressText: ({ hideLabels }: StyleProps) => ({
    ...styleIf(!hideLabels, {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[600],
      color: theme.palette.text.secondary,
      fontWeight: 400,
    }),
  }),
}))

export const MemberCurriculumProgressBarSkeleton: React.FC = () => {
  return (
    <div>
      <DiscoTextSkeleton variant={"body-sm"} width={30} />
      <Skeleton variant={"rect"} width={72} height={8} />
    </div>
  )
}

export default Relay.withSkeleton<MemberCurriculumProgressBarProps>({
  component: MemberCurriculumProgressBar,
  skeleton: MemberCurriculumProgressBarSkeleton,
})
