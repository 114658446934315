import { useSignupUser } from "@/authentication/signup/util/useSignupUser"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { AuthUserData, useAuthUser } from "@/core/context/AuthUserContext"
import { useDrawerContext } from "@/core/context/DrawerContext"
import CheckoutFormApplicationSection from "@/product/checkout/form/application/CheckoutFormApplicationSection"
import CheckoutFormOrganizationFormSection from "@/product/checkout/form/organization-form/CheckoutFormOrganizationFormSection"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import useCompleteCheckoutMutation from "@/product/checkout/store/useCompleteCheckoutMutation"
import useUpdateCheckoutMutation from "@/product/checkout/store/useUpdateCheckoutMutation"
import { ProductRegistrationApplyStep_SubmitApplicationMutation } from "@/product/register/steps/__generated__/ProductRegistrationApplyStep_SubmitApplicationMutation.graphql"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import CheckmarkIcon from "@assets/disco/icons/color-icons/checkmark-circle.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoCheckboxSkeleton,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  store: CheckoutStore
}

function ProductRegistrationApplyStep({ store }: Props) {
  const activeOrganization = useActiveOrganization()
  const drawer = useDrawerContext()
  const classes = useStyles()
  const updateCheckout = useUpdateCheckoutMutation(store)
  const completeCheckout = useCompleteCheckoutMutation(store)
  const signupUser = useSignupUser()
  const { authUser } = useAuthUser()
  const userTimezone = useUserTimezone()
  const [isCreatingUser, setIsCreatingUser] = useState(false)
  const [isApplied, setIsApplied] = useState(false)

  const submitApplication =
    Relay.useAsyncMutation<ProductRegistrationApplyStep_SubmitApplicationMutation>(
      graphql`
        mutation ProductRegistrationApplyStep_SubmitApplicationMutation(
          $input: SubmitCheckoutApplicationInput!
        ) {
          response: submitCheckoutApplication(input: $input) {
            node {
              id
              status
            }
            errors {
              field
              message
            }
          }
        }
      `
    )

  if (drawer.setFullScreen) drawer.setFullScreen(true)
  if (!store.checkout?.hasApplication && !store.checkout?.organizationForm) return null

  if (isApplied) {
    return (
      <div className={classes.completedApplication}>
        <CheckmarkIcon
          data-testid={"ContentUsageListItem.completion-icon.complete"}
          className={classes.progressIcon}
        />
        <DiscoText variant={"heading-sm-700"}>{"Application Submitted"}</DiscoText>
        <DiscoText variant={"body-md"} color={"text.secondary"}>
          {
            "Your application has been submitted. You'll receive an email when it's processed."
          }
        </DiscoText>
      </div>
    )
  }

  return (
    <>
      <DiscoText variant={"body-md-600"} marginBottom={2}>
        {"Application"}
      </DiscoText>

      <CheckoutFormApplicationSection store={store} />
      <CheckoutFormOrganizationFormSection store={store} />

      <div className={classes.buttons}>
        <DiscoButton
          color={"grey"}
          variant={"outlined"}
          onClick={handleBack}
          width={"100%"}
        >
          {"Back"}
        </DiscoButton>

        <DiscoButton
          type={"submit"}
          disabled={!canSubmitForm()}
          shouldDisplaySpinner={store.isSubmitting}
          onClick={handleSubmit}
          width={"100%"}
        >
          {activeOrganization?.checkoutVersion === "stripe_acacia"
            ? "Submit"
            : store.requiresPaymentStep
            ? "Next Step"
            : store.checkout?.hasApplication
            ? "Submit"
            : store.isGifted
            ? "Claim this Gift"
            : "Complete Registration"}
        </DiscoButton>
      </div>
    </>
  )

  function handleBack() {
    store.currentStep = "registration"
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    if (!canSubmitForm()) return

    try {
      if (authUser) {
        updateUserInStore(authUser)
      } else {
        // signup user if they are not logged in
        setIsCreatingUser(true)
        const newUser = await signupUser({
          email: store.profile.email!,
          firstName: store.profile.firstName,
          lastName: store.profile.lastName,
          timezone: userTimezone,
        })

        updateUserInStore(newUser)
      }

      const isValid = await updateCheckout()
      if (!isValid) return

      // If on the latest checkout, mark the application as submitted but go no further
      if (activeOrganization?.checkoutVersion === "stripe_acacia") {
        const { response } = await submitApplication({
          input: { checkoutId: store.checkout?.id || "" },
        })
        if (!response.node || response.errors) return
        setIsApplied(true)
      }

      // Otherwise use the old checkout flow
      else if (store.requiresPaymentStep) {
        // Paid Product
        store.currentStep = "payment"
      } else {
        // Free Product
        await completeCheckout()
      }
    } catch (err) {
      displayErrorToast(err)
    } finally {
      setIsCreatingUser(false)
    }
  }

  function updateUserInStore(user: AuthUserData<true>) {
    runInAction(() => {
      store.profile.userId = Relay.rawId(user.id)
      store.profile.email = user.email
      store.profile.firstName = user.firstName
      store.profile.lastName = user.lastName
      store.profile.timezone = user.timezone
    })
  }

  function canSubmitForm(): boolean {
    if (isCreatingUser) return false
    if (store.isSubmitting) return false
    if (!store.canCompleteRegistrationStep) return false
    return true
  }
}

const useStyles = makeUseStyles((theme) => ({
  buttons: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  completedApplication: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2.5),
    textAlign: "center",
  },
  progressIcon: {
    margin: 0,
    flexShrink: 0,
    width: "40px",
    height: "40px",
  },
}))

export const ProductRegistrationApplyStepSkeleton: React.FC<{
  store: CheckoutStore
}> = (props) => {
  const { store } = props
  const theme = useTheme()
  const classes = useStyles()
  return (
    <>
      <DiscoTextSkeleton variant={"body-md-600"} width={"45%"} />

      <DiscoTextSkeleton variant={"body-sm"} width={"80%"} />
      {store.checkout?.application && <Skeleton width={"100%"} height={"200px"} />}
      {store.checkout?.organizationForm && (
        <div style={{ paddingBottom: theme.spacing(2) }}>
          <DiscoCheckboxSkeleton />
        </div>
      )}

      <div style={{ paddingBottom: theme.spacing(2) }}>
        <DiscoCheckboxSkeleton />
      </div>

      <div className={classes.buttons}>
        <DiscoButtonSkeleton width={"100%"} />
        <DiscoButtonSkeleton width={"100%"} />
      </div>
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ProductRegistrationApplyStep),
  skeleton: ProductRegistrationApplyStepSkeleton,
})
