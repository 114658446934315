import ExperienceSettingsTooltip from "@/product/settings/ExperienceSettingsTooltip"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
export interface ExperienceSettingsLabelProps extends TestIDProps {
  title: string
  tooltipContent?: string
  sectionId?: string
  learnMoreUrl?: string
}

function ExperienceSettingsLabel({
  title,
  learnMoreUrl = "https://support.disco.co/hc/en-us/articles/27958952014996-Product-Settings",
  sectionId,
  tooltipContent,
  testid,
}: ExperienceSettingsLabelProps) {
  const classes = useStyles()

  return (
    <div className={classes.label} data-testid={testid}>
      <DiscoText variant={"body-md-600"}>{title}</DiscoText>
      {tooltipContent && (
        <ExperienceSettingsTooltip
          content={
            <DiscoText color={"common.white"} variant={"body-xs-500"}>
              {tooltipContent}
            </DiscoText>
          }
          learnMoreLink={
            learnMoreUrl
              ? `${learnMoreUrl}${sectionId ? `#${sectionId}` : ""}`
              : undefined
          }
        />
      )}
    </div>
  )
}
const useStyles = makeUseStyles((theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default ExperienceSettingsLabel
