import { useLabel } from "@/core/context/LabelsContext"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import ExperienceSettingsTooltip from "@/product/settings/ExperienceSettingsTooltip"
import { ExperienceSettingsDurationFieldsFragment$key } from "@/product/settings/__generated__/ExperienceSettingsDurationFieldsFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoFormControl,
  DiscoSection,
  DiscoSwitch,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoDatePicker, { DateDetails } from "@disco-ui/date/DiscoDatePicker"
import DiscoDateRangePicker, {
  DateRangeDetails,
} from "@disco-ui/date/DiscoDateRangePicker"
import DateUtils from "@utils/date/dateUtils"
import { compensateForTimeZone } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { addWeeks, isAfter } from "date-fns"
import { format } from "date-fns-tz"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
interface ExperienceSettingsDurationFieldsProps extends TestIDProps {
  form: {
    initialState: Partial<Pick<ExperienceSettingsFormState, "startDate" | "endDate">>
    state: Partial<
      Pick<
        ExperienceSettingsFormState,
        "allowEarlyAccess" | "waitingRoomEndsAt" | "startDate" | "endDate"
      >
    >
    errorsByField: Record<string, string[] | undefined>
  }
  mode: "create" | "edit"
  productKey?: ExperienceSettingsDurationFieldsFragment$key
}

function ExperienceSettingsDurationFields({
  form,
  mode = "edit",
  testid = "ExperienceSettingsDurationFields",
  productKey,
}: ExperienceSettingsDurationFieldsProps) {
  const membersLabel = useLabel("organization_member")
  const experienceLabel = useLabel("experience")
  const startDate = DateUtils.getDateObjectFromDateString(form.state.startDate)
  const endDate = DateUtils.getDateObjectFromDateString(form.state.endDate)
  const hasStarted = startDate ? isAfter(new Date(), startDate) : false
  const hasEnded = endDate ? isAfter(new Date(), endDate) : false
  const currentStartDate = form.state.startDate
    ? compensateForTimeZone(new Date(form.state.startDate))
    : null
  const currentEndDate = form.state.endDate
    ? compensateForTimeZone(new Date(form.state.endDate))
    : null

  const classes = useStyles()

  const product = useFragment<ExperienceSettingsDurationFieldsFragment$key>(
    graphql`
      fragment ExperienceSettingsDurationFieldsFragment on Product {
        isInPathway
      }
    `,
    productKey || null
  )
  const disabled = hasEnded || product?.isInPathway

  return (
    <div>
      <DiscoFormControl
        error={
          Boolean(form.errorsByField.startDate) || Boolean(form.errorsByField.endDate)
        }
        errorMessages={[
          ...(form.errorsByField.startDate || []),
          ...(form.errorsByField.endDate || []),
        ]}
      >
        <DiscoTooltip
          disabled={!disabled}
          content={
            hasEnded
              ? `Cannot change Fixed Duration after the ${experienceLabel.singular} has ended.`
              : `Cannot enable Fixed Duration because this ${experienceLabel.singular} is part of a Pathway.`
          }
        >
          {/* Wraper required so tooltip will trigger when switch is disabled */}
          <div className={classes.switchContainer}>
            <DiscoSwitch
              name={"toggle-fixed-duration"}
              checked={Boolean(form.state.startDate && form.state.endDate)}
              testid={`${testid}.switch.fixed`}
              disabled={disabled}
              label={
                <ExperienceSettingsLabel
                  testid={`${testid}.label`}
                  title={"Fixed Duration"}
                  learnMoreUrl={
                    "https://support.disco.co/hc/en-us/articles/28537074075284-Early-Access-to-a-Product"
                  }
                  sectionId={"h_01HGERMVFWDJ676H10HF11D7JP"}
                  tooltipContent={`Add a start and stop date to your ${experienceLabel.singular}. Ideal for ${experienceLabel.plural} like cohort based courses. Before the start date, ${membersLabel.plural} can register but not participate or see any content. On the start date, all ${membersLabel.plural} will have full access. The end date is for informational and registration purposes only, ${membersLabel.plural} will continue to have access to the ${experienceLabel.singular}.`}
                />
              }
              sublabel={
                <DiscoText variant={"body-sm"}>
                  {`Specify set dates when this ${experienceLabel.singular} will be available.`}
                </DiscoText>
              }
              onChange={(checked) => {
                if (checked) {
                  form.state.startDate =
                    form.initialState.startDate ||
                    format(addWeeks(new Date(), 2), "yyyy-MM-dd")
                  form.state.endDate =
                    form.initialState.endDate ||
                    format(addWeeks(new Date(), 5), "yyyy-MM-dd")
                } else {
                  form.state.startDate = null
                  form.state.endDate = null
                }
              }}
            />
          </div>
        </DiscoTooltip>
      </DiscoFormControl>

      {(form.state.startDate || form.state.endDate) && (
        <DiscoSection className={classes.durationContainer}>
          <DiscoDateRangePicker
            testid={`${testid}.DiscoDateRangePicker`}
            value={{ from: currentStartDate!, to: currentEndDate! }}
            disabled={disabled}
            onChange={handleChangeDateRange}
            minDate={disabled ? undefined : new Date()}
          />

          {mode === "edit" && (
            <DiscoFormControl
              description={
                <div className={classes.label}>
                  <DiscoText variant={"body-sm"} color={"text.secondary"}>
                    {"Allow early access before the start date."}
                  </DiscoText>
                  <ExperienceSettingsTooltip
                    content={
                      <DiscoText color={"common.white"} variant={"body-xs-500"}>
                        {`Enabling Early access will allow registered ${membersLabel.plural} of the ${experienceLabel.singular} to access Channels, Members and other apps before the Start Date. Early access will not impact Modules with a specific release date.`}
                      </DiscoText>
                    }
                    learnMoreLink={
                      "https://support.disco.co/hc/en-us/articles/28537074075284-Early-Access-to-a-Product"
                    }
                  />
                </div>
              }
              error={Boolean(form.errorsByField.waitingRoomEndsAt)}
              errorMessages={form.errorsByField.waitingRoomEndsAt}
              variant={"two-column"}
              className={classes.waitingRoomForm}
            >
              <div className={classes.waitingRoomContainer}>
                <DiscoSwitch
                  testid={`${testid}.waitingRoom.switch`}
                  checked={form.state.allowEarlyAccess}
                  onChange={handleWaitingRoomToggle}
                  classes={{ root: classes.switch }}
                  disabled={hasStarted}
                />

                <DiscoDatePicker
                  testid={`${testid}.waiting-room-ends-at`}
                  value={
                    form.state.waitingRoomEndsAt
                      ? compensateForTimeZone(new Date(form.state.waitingRoomEndsAt))
                      : compensateForTimeZone(new Date(form.state.startDate!))
                  }
                  disabled={!form.state.allowEarlyAccess || hasStarted}
                  onChange={handleWaitingRoomChange}
                  maxDate={currentStartDate || undefined}
                />
              </div>
            </DiscoFormControl>
          )}
        </DiscoSection>
      )}
    </div>
  )

  function handleChangeDateRange(details: DateRangeDetails) {
    if (!details.from.value) return

    form.state.startDate = details.from.formatted["yyyy-MM-dd"]
    form.state.endDate = details.to.formatted["yyyy-MM-dd"]

    if (isAfter(new Date(form.state.waitingRoomEndsAt!), new Date(details.from.value))) {
      form.state.waitingRoomEndsAt = details.from.formatted["yyyy-MM-dd"]
    }
  }

  function handleWaitingRoomToggle() {
    form.state.allowEarlyAccess = !form.state.allowEarlyAccess
  }

  function handleWaitingRoomChange(details: DateDetails) {
    if (form.state.allowEarlyAccess && details.value) {
      form.state.waitingRoomEndsAt = details.formatted["yyyy-MM-dd"]
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1.25),
    gap: theme.spacing(1),
  },
  durationContainer: {
    width: "100%",
    boxShadow: theme.palette.groovyDepths.insideCard,
    padding: theme.spacing(2.5),
    margin: theme.spacing(-1.5, 0, 3.5, 0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  waitingRoomForm: {
    width: "100%",
    alignItems: "center",
  },
  waitingRoomContainer: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "space-between",
  },
  switch: {
    marginLeft: theme.spacing(-2),
  },
  switchContainer: {
    display: "inline-flex",
    flexDirection: "column",
  },
}))

export default observer(ExperienceSettingsDurationFields)
